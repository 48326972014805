import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import unified from "unified"
import remarkHtml from "remark-html"
import remarkParse from "remark-parse"
import path from "path"
import pathParse from "path-parse"
import S from "string"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const [showHideAnswer, alternateShowHideAnswer] = useState(false)

  // https://www.gatsbyjs.com/tutorial/part-six/
  const { markdownRemark, previous, next } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const buttonFlex = {
    display: "flex",
    justifyContent: "space-between",
  }

  const buttonPadding = {
    marginLeft: "10px",
  }

  // convert markdown to html https://nicedoc.io/remarkjs/remark-html
  const explanation = frontmatter.explanation
    ? unified()
        .use(remarkParse)
        .use(remarkHtml)
        .processSync(frontmatter.explanation)
    : ""

  const references = frontmatter.references
    ? unified()
        .use(remarkParse)
        .use(remarkHtml)
        .processSync(frontmatter.references)
    : ""

  const answer = frontmatter.answer
    ? unified().use(remarkParse).use(remarkHtml).processSync(frontmatter.answer)
    : ""

  function getSlugOrLink(title, fileAbsolutePath) {
    if (!title && !fileAbsolutePath) return null

    const filename = path.basename(fileAbsolutePath)
    const withoutExtension = pathParse(filename).name
    const slugFile = S(withoutExtension).slugify().s
    return slugFile
  }

  const previousLink =
    previous.edges.length > 0
      ? getSlugOrLink(
          previous.edges[0].node.frontmatter.title,
          previous.edges[0].node.fileAbsolutePath
        )
      : null
  const nextLink =
    next.edges.length > 0
      ? getSlugOrLink(
          next.edges[0].node.frontmatter.title,
          next.edges[0].node.fileAbsolutePath
        )
      : null

  let showHideClass = showHideAnswer ? "show" : "hide"

  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          {/* <h1>{frontmatter.title}</h1>
          <h2>{frontmatter.date}</h2> */}
          {frontmatter.number && frontmatter.title && (
            <h1>
              {frontmatter.number}# {frontmatter.title}
            </h1>
          )}

          <section>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </section>

          {frontmatter.options && (
            <section>
              <ul>
                {frontmatter.options.map((s, i) => {
                  return <li key={i}>{s}</li>
                })}
              </ul>
            </section>
          )}

          <section>
            <div style={buttonFlex}>
              <button onClick={() => alternateShowHideAnswer(!showHideAnswer)}>
                {showHideAnswer ? "Hide Answer" : "Show Answer"}
              </button>
              <div>
                {previousLink && (
                  <Link to={"/".concat(previousLink)}>
                    <button>Previous</button>
                  </Link>
                )}

                {nextLink && (
                  <Link to={"/".concat(nextLink)} style={buttonPadding}>
                    <button>Next</button>
                  </Link>
                )}
              </div>
            </div>
          </section>

          {answer && (
            <section className={showHideClass}>
              <h2>Correct Answer:</h2>

              <div dangerouslySetInnerHTML={{ __html: answer }} />
            </section>
          )}

          {explanation && (
            <section className={showHideClass}>
              <h2>Explanation:</h2>

              <div dangerouslySetInnerHTML={{ __html: explanation }} />
            </section>
          )}

          {references && (
            <section className={showHideClass}>
              <h2>References:</h2>

              <div dangerouslySetInnerHTML={{ __html: references }} />
            </section>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($fileAbsolutePath: String!, $number: Int) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        number
        title
        options
        explanation
        references
        answer
      }
    }
    previous: allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { number: { lt: $number } } }
      sort: { fields: frontmatter___number, order: DESC }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            number
            title
          }
        }
      }
    }
    next: allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { number: { gt: $number } } }
      sort: { fields: frontmatter___number }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            number
            title
          }
        }
      }
    }
  }
`
